import { BLVD_LOGIN_LINK } from 'utils/constants/Helpers'

export const ManageAppointmentButton = () => {
  return (
    <div className="appointment-link underline">
      <a href={BLVD_LOGIN_LINK} target="_blank" rel="noreferrer">
        Manage Appointments
      </a>
    </div>
  )
}
