import MonthlyFacial from '../../assets/icons/monthly-facial.svg'
import Enhancement from '../../assets/icons/enhancement.svg'
import Product from '../../assets/icons/products.svg'
import Exclusive from '../../assets/icons/exclusive-benefits.svg'

export const SIGNUP_MESSAGES = {
  INVALID_EMAIL: 'Email address is not valid',
  REQUIRED: 'This Field is Required',
  PASSWORD_INVALID: 'Password must be 5 characters',
  PASSWORD_NOT_MATCH: 'Passwords do not match',
  EMAIL_EXISITS:
    'The email entered is associated with an existing Heyday account',
  SIGNUP_SUCCESS: 'Account created successfully',
  SIGNUP_FAILED: 'Invalid email address',
  INVALID_NAME: 'Enter valid name',
  SHOPIFY_STATUS_SIGNUP:
    '👋 Hey, it looks like we already have your email in our system. We just sent you an email to finish setting up your account. ',
  SHOPIFY_STATUS_LOGIN:
    '👋 It’s great to see you again! It looks like you need to finish setting up your account before logging in. Check your email for next steps.',
  PHONE_NUMBER_REQUIRED: 'This Field is Required',
  PHONE_NUMBER_INVALID: 'Phone Number is not valid',
}

export const FORGET_MESSAGES = {
  LIMIT_EXCEEDED: 'Request Limit Exceeded',
  SUCCESS: 'E-Mail Send Successfully',
  ENTER_VALID_EMAIL: 'Enter valid email address',
}

export const LOGIN_MESSAGES = {
  ERROR: `That email and password combination wasn’t
   recognized. Please try again.`,
  SUCCESS: 'Login Success',
  INVALID_ACCESS_TOKEN: 'Invalid access token', // this message won't be shown to user
}

export const APPOINTMENT_MESSAGES = {
  INVALID_NAME: 'Entered Name is invalid',
  REQUIRED: (fieldName: string) => `${fieldName} is required`,
}

export const GENERAL_ERROR_MESSAGE =
  'Oops. Something went wrong. Please try again later.'

export const CART_NOT_CREATED = 'Cart cannot be created, please try again'

export const GENERAL_APPOINTMENT_ERROR_MESSAGE =
  'Oops! Something went wrong. Please start over and try again. If this issue still persists, please contact customer support at hello@heydayskincare.com for further help. We apologize for any inconvenience.'

export const CART_ERRORS = ['CART_TIME_NOT_AVAILABLE', 'CART_MISSING_ITEM_TIME']
export const TIME_NOT_AVAILABLE_MESSAGE =
  'Unfortunately the time you selected is no longer available. Please return to the appointment selection screen and select a different time. If you continue to face this error, please contact customer service at hello@heydayskincare.com. Our team is standing by to help further.'

export const ENHANCEMENT_REMOVED_MESSAGE = 'Enhancement Removed'

export const MEMBERSHIP_DATA_LIST = [
  {
    icon: MonthlyFacial,
    title: 'A Monthly Discounted Facial',
    subTitle: 'with additional facials for $85.',
  },
  {
    icon: Enhancement,
    title: '50% Off Enhancements',
    subTitle: 'for microcurrent, peels and more.',
  },
  {
    icon: Product,
    title: '15% Off Products',
    subTitle: 'with 20% off at sign-up.',
  },
  {
    icon: Exclusive,
    title: 'Exclusive Benefits',
    subTitle: 'shop events, surprises & more',
  },
]

export const PAYMENT_MESSAGES = {
  CARD_DELETE_SUCCESS: 'Credit card successfully deleted',
}

export const MEMBERSHIP_MESSAGES = {
  AGREEMENT_SIGNED: 'Membership agreement signed!',
  ALREADY_MEMBER:
    "You're already a member, so we can't add a membership to the cart.",
}

export const MEMBERSHIP_CANCEL_NOTE =
  'If you wish to cancel your membership after signing up, email us at members@heydayskincare.com. We require 30 days notice for cancellation. Memberships will be cancelled effective 30 days following our receipt of such cancellation request.'

export const OFFER_CODE_EXIST_MESSAGE = 'Offer code does not exist.'
export const OFFER_CODE_APPLIED_MESSAGE = 'Offer already applied.'
export const OFFER_NOT_APPLIED = 'Sorry, this promo code is not valid.'

export const MEMBERSHIP_BANNER = {
  COMMON_TITLE: 'Monthly Membership',
  COMMON_TEXT:
    'Soak Up The Perks: Free monthly facials, 50% off enhancements, and 15% off products, any time.',
}

export const SUMMER_PROMO_DESCRIPTION = (isMember: boolean) =>
  `${isMember ? 'Members, simply' : 'Simply'} mention ‘Summer Glow’ promotion at your facial and one free enhancement will be applied in shop.`

export const RETURNING_NON_MEMBER_BANNER_TEXT =
  'Prices may not reflect discounts for Bi-Monthly Members or Facial Package holders. You will not be charged until you complete your services in shop, and the correct pricing will be applied.'
