/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FormControl,
  FormHelperText,
  Grid2 as Grid,
  InputAdornment,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material'
import React, {
  FC,
  ReactElement,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react'
import { ReactComponent as PlusCircleIcon } from '../../../assets/icons/plus-circle.svg'
import { ReactComponent as TagIcon } from '../../../assets/icons/Tag.svg'
import { ReactComponent as TrashIcon } from '../../../assets/icons/trash.svg'
import { AppContext } from '../../../providers/context/App'
import {
  addOfferToCart,
  removeOfferFromCart,
} from '../../../services/PromoCode'
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline'
import './appointment.scss'
import { CartOffer } from '../../../utils/types/promoCodeTypes'
import { some } from 'lodash'
import { getUserAppointments, getUserInfo } from '../../../services/User'
import { ErrorContext } from '../../../providers/context/Error'
import moment from 'moment'
import { useLoading } from 'providers/context/Loading'
import {
  OFFER_CODE_APPLIED_MESSAGE,
  OFFER_CODE_EXIST_MESSAGE,
  OFFER_NOT_APPLIED,
} from 'utils/constants/Messages'
import {
  BLACK_FRIDAY_PROMO_CODE,
  BLACK_FRIDAY_PROMO_END_DATE,
  BLACK_FRIDAY_PROMO_START_DATE,
  SAY_HEY_PROMO_CODE,
} from 'utils/constants/Helpers'

const AppointmentPromo: FC<{}> = (): ReactElement => {
  const { setLoading } = useLoading()
  const { appMasterData, updateData } = useContext(AppContext)
  const [showPromoText, setShowPromoText] = useState(false)
  const [error, setError] = useState('')
  const [promoCodes, setPromoCodes] = useState<CartOffer[]>([])
  const [promoText, setPromoText] = useState('')
  const [isLoad, setIsLoad] = useState(false)
  const { updateErrorData } = useContext(ErrorContext)

  let specialOfferCode = ''

  const removeCode = async (code: CartOffer) => {
    try {
      const removedCard = await removeOfferFromCart(
        appMasterData.cart.cartId,
        code.id
      )
      if (removedCard.removeCartOffer.cart.id) {
        const updatedCodes = promoCodes.filter((d) => d.id !== code.id)
        setPromoCodes(updatedCodes)
        updateData({
          cart: {
            ...appMasterData.cart,
            summary: removedCard.removeCartOffer.cart.summary,
            isPromoCodeApplied: Boolean(updatedCodes.length > 0),
            promoCodes: updatedCodes,
          },
        })
      }
    } catch (e) {
      console.error(e)
    }
  }
  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setPromoText(event.target.value)
    setError('')
  }

  // Function to Apply the promo to cart and update the state
  const addNewPromoAndUpdate = async (promoCode: string) => {
    // Disabling Black Friday Promo for member users
    if (
      promoCode === BLACK_FRIDAY_PROMO_CODE &&
      appMasterData.userInfo.is_member
    ) {
      return setError(OFFER_CODE_EXIST_MESSAGE)
    }
    try {
      const promoCodeData = await addOfferToCart(
        appMasterData.cart.cartId,
        promoCode
      )
      if (!promoCodeData.data.addCartOffer) {
        return setError(OFFER_CODE_EXIST_MESSAGE)
      }

      if (!promoCodeData.data.addCartOffer.offer?.applied) {
        return setError(OFFER_NOT_APPLIED)
      }

      const updatedCodes = [
        ...promoCodes,
        promoCodeData.data.addCartOffer.offer,
      ]
      setPromoCodes(updatedCodes)
      updateData({
        cart: {
          ...appMasterData.cart,
          summary: promoCodeData.data.addCartOffer.cart.summary,
          isPromoCodeApplied: true,
          promoCodes: updatedCodes,
        },
      })
      setShowPromoText(false)
    } catch (e: any) {
      setError(e?.error?.message)
    }
  }

  const applyPromo = async () => {
    const promoExist = some(promoCodes, { code: promoText.toUpperCase() })
    if (
      promoText.toUpperCase() === SAY_HEY_PROMO_CODE &&
      appMasterData.previousBookedAppointments.length > 0
    ) {
      return setError(OFFER_NOT_APPLIED)
    }

    if (promoExist) {
      return setError(OFFER_CODE_APPLIED_MESSAGE)
    }

    if (promoText) {
      await addNewPromoAndUpdate(promoText)
      setPromoText('')
    }
  }

  const applySpecialOffer = async () => {
    try {
      const response = await getUserInfo()
      const userAppointment = await getUserAppointments()
      if (userAppointment.length === 0) {
        setPromoText(specialOfferCode)
        setIsLoad(true)
        await applyPromo()
      } else {
        setIsLoad(false)
        setPromoText('')
      }
      updateData({ previousBookedAppointments: userAppointment })
      return { success: true, data: response }
    } catch (error: any) {
      updateErrorData(error)
      return { success: false }
    }
  }
  const removeSpecialOffer = () => {
    const findPromoCodeExist = appMasterData.cart.promoCodes.findIndex(
      (a) => a.code === specialOfferCode
    )
    if (findPromoCodeExist > -1) {
      updateData({
        cart: {
          ...appMasterData.cart,
          promoCodes: appMasterData.cart.promoCodes.filter(
            (a) => a.code !== specialOfferCode
          ),
          isPromoCodeApplied: false,
        },
      })
    }
  }

  // Determines if the Black Friday promo code can be applied based on certain conditions.
  const canApplyBlackFridayPromoCode = useMemo(() => {
    const excludedLocations = ['East Cobb', 'Assembly Row', 'Bellaire']

    const currentDate = moment.utc()
    const autoEnableDate = moment.utc(BLACK_FRIDAY_PROMO_START_DATE)
    const autoDisableDate = moment.utc(BLACK_FRIDAY_PROMO_END_DATE)

    // Returns true if all conditions for applying the promo are met.
    return (
      !appMasterData.userInfo.is_member && // User is not a member
      appMasterData.isLoggedIn && // User is logged in
      currentDate.isSameOrAfter(autoEnableDate) && // Current date is on or after the promo start date
      currentDate.isSameOrBefore(autoDisableDate) && // Current date is on or before the promo end date
      !excludedLocations.includes(appMasterData.location.name) // Some locations are excluded
    )
  }, [
    appMasterData.isLoggedIn,
    appMasterData.userInfo.is_member,
    appMasterData.location.name,
  ])

  const isBlackFridayPromoExist = some(promoCodes, {
    code: BLACK_FRIDAY_PROMO_CODE,
  })
  // Function to auto apply the Black Friday promo if conditions are met.
  const autoApplyBlackFridayPromo = async () => { // NOSONAR
    // Checks if the promo code exists in the list of promo codes.
    // If promo doesn't exist and conditions allow, add the promo code and update.
    if (!isBlackFridayPromoExist && canApplyBlackFridayPromoCode) {
      setLoading(true)
      await addNewPromoAndUpdate(BLACK_FRIDAY_PROMO_CODE)
      setLoading(false)
    }
  }

  // Function to auto remove the Black Friday promo if user is logged in on checkout screen.
  const removeBlackFridayPromoCode = async () => { // NOSONAR
    if (isBlackFridayPromoExist) {
      await removeCode({
        id: promoCodes.find((p) => p.code === BLACK_FRIDAY_PROMO_CODE)?.id!,
      } as CartOffer)
    }
  }

  useEffect(() => {
    if (
      appMasterData.location.marketingSupport.enableNewClientPromo &&
      !!appMasterData.location.marketingSupport.confirmationPromo?.offerCode
    ) {
      specialOfferCode =
        // eslint-disable-next-line react-hooks/exhaustive-deps
        appMasterData.location.marketingSupport.confirmationPromo.offerCode ??
        ''
    }

    if (appMasterData?.isLoggedIn && !appMasterData.isLaserFacial) {
      if (appMasterData.location.marketingSupport.enableNewClientPromo) {
        if (appMasterData.cart.promoCodes.length === 0) {
          applySpecialOffer().catch((e) => console.error(e))
        }
      } else {
        removeSpecialOffer()
      }
    }
  }, [isLoad, appMasterData.isLoggedIn, appMasterData.location.locationId])

  const shouldApplyPromo =
    !showPromoText && // Promotional text should not be shown
    !appMasterData.cart.isPromoCodeApplied // Only one promo allowed

  return (
    <div className="promo-section">
      <Grid
        size={{
          xs: 12,
        }}
        className="selected-promo"
      >
        <List>
          {appMasterData.cart.promoCodes.length > 0 && (
            <Typography className="promo-text">PROMO CODES</Typography>
          )}
          {appMasterData.cart.promoCodes.map((code) => {
            return (
              <ListItem key={code.id}>
                <ListItemAvatar>
                  <TagIcon />
                </ListItemAvatar>
                <ListItemText
                  className="list-text"
                  primary={code.code}
                  secondary={code.name}
                />
                <ListItemSecondaryAction>
                  {' '}
                  <TrashIcon
                    className="trash-icon"
                    onClick={() => {
                      removeCode(code).catch((e) => console.error(e))
                    }}
                  />
                </ListItemSecondaryAction>
              </ListItem>
            )
          })}
        </List>
      </Grid>

      {shouldApplyPromo && (
        <div className="add-promo-btn" onClick={() => setShowPromoText(true)}>
          <PlusCircleIcon />{' '}
          <span className="addpromo-text">Add a promo code</span>
        </div>
      )}

      {showPromoText && (
        <Grid container spacing={3} className="add-promo-field">
          <Grid
            size={{
              xs: 12,
            }}
          >
            <FormControl error={true} variant="standard" fullWidth={true}>
              <TextField
                id="standard-basic"
                label="Promo Code"
                variant="filled"
                fullWidth={true}
                value={promoText}
                onChange={handleChange}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <span
                        onClick={() => {
                          applyPromo().catch((e) => console.error(e))
                        }}
                        onKeyDown={() => {
                          applyPromo().catch((e) => console.error(e))
                        }}
                        className="apply-btn"
                      >
                        Apply
                      </span>
                    </InputAdornment>
                  ),
                }}
              />
              {!!error && (
                <FormHelperText className="error-text">
                  <ErrorOutlineIcon className="err-icon" />
                  <span>{error}</span>
                </FormHelperText>
              )}
            </FormControl>
          </Grid>
        </Grid>
      )}
    </div>
  )
}

export default React.memo(AppointmentPromo)
