import { FC, useContext } from 'react'

import { HEALTH_CHECK } from '../../../utils/constants/Enums'
import { AppContext } from 'providers/context/App'
import { facialPreCheck, laserPreCheck } from 'utils/constants/Helpers'

import './health-check.scss'

interface Props {
  healthCheck: HEALTH_CHECK
}

const HealthCheckContent: FC<Props> = ({ healthCheck }) => {
  const { appMasterData } = useContext(AppContext)
  const isLaserFacial = appMasterData.isLaserFacial
  const checkInList = isLaserFacial ? laserPreCheck : facialPreCheck

  return (
    <div className="content-wrapper health-check-content">
      <label className="head-label">PRE-FACIAL check-in</label>
      {healthCheck === HEALTH_CHECK.YES ? (
        <>
          <h3>Let's get some face time at a later date. </h3>
          <p>
            We want to be safe rather than sorry. Please select another
            appointment time that's 14 days after your most recent cosmetic
            treatment or cold sore.
          </p>
        </>
      ) : (
        <>
          <h3>
            Almost there! ✨ <br /> {checkInList.header}
          </h3>
          <ul className="facialCheckList">
            {checkInList.items.map((item) => (
              <li key={`checkList-${item}`} data-testid="list-item">
                {item}
              </li>
            ))}
          </ul>
          <p>
            If so, sit tight! <strong>{checkInList.footer}</strong>
          </p>
        </>
      )}
    </div>
  )
}

export default HealthCheckContent
