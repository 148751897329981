import { Dispatch, FC, SetStateAction } from 'react'
import { Button } from '@mui/material'
import './health-check.scss'
import { HEALTH_CHECK } from '../../../utils/constants/Enums'
import {} from '../../../services/Analytics'
import { conditionalClasses } from '../../../utils/helper-functions'

interface Props {
  healthCheck: HEALTH_CHECK
  setHealthCheck: Dispatch<SetStateAction<HEALTH_CHECK>>
  navigateToAppointment: () => void
  navigateToDateSelection: () => void
}

const HealthCheckFooter: FC<Props> = ({
  healthCheck,
  setHealthCheck,
  navigateToAppointment,
  navigateToDateSelection,
}) => {
  return (
    <div
      className={conditionalClasses(
        healthCheck !== HEALTH_CHECK.YES,
        'dual-buttons',
        '',
        'footer-continue bordered neutral-background'
      )}
    >
      {healthCheck === HEALTH_CHECK.YES ? (
        <Button
          fullWidth
          variant="contained"
          disableElevation
          className="app-btn"
          onClick={() => {
            navigateToDateSelection()
          }}
        >
          Choose Another Date
        </Button>
      ) : (
        <>
          <Button
            fullWidth
            variant="contained"
            disableElevation
            className="app-btn app-btn-outlined"
            onClick={() => {
              setHealthCheck(HEALTH_CHECK.YES)
            }}
          >
            Yes
          </Button>
          <Button
            fullWidth
            variant="contained"
            disableElevation
            className="app-btn"
            onClick={() => {
              navigateToAppointment()
            }}
          >
            No
          </Button>
        </>
      )}
    </div>
  )
}

export default HealthCheckFooter
