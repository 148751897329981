export const services = [
  {
    name: '50 Minute Facial',
    short:
      'Our signature facial includes an in-depth skin analysis, followed by personalized',
    long: 'Our signature facial includes an in-depth skin analysis, followed by personalized treatments using our exclusive HeydayPro™ products. Your facial will likely include exfoliation, extractions, facial massage, and aromatherapy.',
    isLaser: false,
    hideMemberPrice: false,
    isMembershipPrice: true,
  },
  {
    name: 'Laser Facial',
    short:
      'Go deep with a comprehensive, 50-minute laser treatment that is calibrated',
    long: `Go deep with a comprehensive, 50-minute laser treatment that is calibrated to your unique skin type using Skintel®, the world's first Melanin Reader™. Safe for all skin types.`,
    isLaser: true,
    hideMemberPrice: false,
    isMembershipPrice: true,
  },
  {
    name: 'Laser Lite + Consultation',
    short:
      'First-time Heyday clients must first have a consultation with one of our',
    long: `First-time Heyday clients must first have a consultation with one of our Laser specialists.

We will analyze your skin, determine the right Laser settings based on your skin type and goals, and conduct an introductory laser treatment to understand your skin's response. You'll leave with a personalized treatment plan for optimal results.

This introductory price cannot be combined with any other first-time facial offer.`,
    isLaser: true,
    hideMemberPrice: true,
    isMembershipPrice: false,
  },
]
