import React from 'react'
import {
  Grid,
  MenuItem,
  Select,
  TextField,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip,
  Button,
  SelectChangeEvent,
} from '@mui/material'
import { ReactComponent as PlusCircleIcon } from 'assets/icons/plus-circle.svg'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import './appointment.scss'
import { deletePaymentMethods, getPaymentMethods } from 'services/Payment'
import { NewCardFormState, PaymentMethod } from 'utils/types/appointmentTypes'
import {
  getBrandImage,
  getBrandName,
  getBrandNameConverter,
} from 'utils/helper-functions/cardBrands'
import InputMask from 'react-input-mask'
import { AppointmentConfirmationContext } from 'providers/context/AppointmentConfirmation'
import { ErrorContext, ErrorInitialData } from 'providers/context/Error'
import { cardTypeValidation, conditionalClasses } from 'utils/helper-functions'
import AppointmentPromo from './appointment-promo'
import { AppContext } from 'providers/context/App'
import { ReactComponent as TrashIcon } from 'assets/icons/red-trash.svg'
import CustomDialog from '../dialog'
import {
  GENERAL_ERROR_MESSAGE,
  PAYMENT_MESSAGES,
} from 'utils/constants/Messages'
import { useSnackbar } from 'notistack'
import CardExpireText from './cardExpireText'
import { AMEX_CARD, NON_AMEX_CARD } from 'utils/constants/Helpers'

type Props = {
  isMembership?: boolean
}

const AppointmentPayment = ({ isMembership }: Props) => {
  const { updateErrorData } = React.useContext(ErrorContext)
  const { updateData } = React.useContext(AppointmentConfirmationContext)
  const { appMasterData } = React.useContext(AppContext)
  const [selectedCard, setSelectedCard] = React.useState('')
  const [cards, setCards] = React.useState<PaymentMethod[]>([])
  const [showAddCard, setShowAddCard] = React.useState(false)
  const [isShow, setIsShow] = React.useState(true)
  const [AMEX, setAMEX] = React.useState(false)
  const [isOpen, setIsOpen] = React.useState(false)
  const [cardId, setCardId] = React.useState('')
  const [values, setValues] = React.useState<NewCardFormState>({
    name: '',
    cardNumber: '',
    expiryMonthYear: '',
    cvv: '',
    zipCode: '',
  })
  const { enqueueSnackbar } = useSnackbar()

  const removeCard = async () => {
    try {
      const res = await deletePaymentMethods(cardId)
      if (res) {
        enqueueSnackbar(PAYMENT_MESSAGES.CARD_DELETE_SUCCESS, {
          variant: 'success',
        })
        await getPaymentInfo()
        setIsOpen(false)
      }
    } catch (error) {
      enqueueSnackbar(GENERAL_ERROR_MESSAGE, { variant: 'error' })
    }
  }

  const handlePopupOpen = (id: string) => {
    setIsOpen(true)
    setCardId(id)
  }

  const onSelectCard = (event: SelectChangeEvent) => {
    setSelectedCard(event.target.value)
    if (event.target.value) {
      setShowAddCard(false)
      updateData({
        selectedCardToken: event.target.value,
        isExistingCard: true,
        selectedCard: cards.find((card) => card.token === selectedCard),
      })
    } else {
      setShowAddCard(true)
      updateData({ selectedCardToken: '', isExistingCard: false })
    }
  }

  const getPaymentInfo = async () => {
    try {
      const paymentMethods = await getPaymentMethods()
      if (paymentMethods.length > 0) {
        const selectedToken = paymentMethods[0].token
        setShowAddCard(false)
        setSelectedCard(selectedToken)
        updateData({
          isExistingCard: true,
          selectedCardToken: selectedToken,
          selectedCard: paymentMethods[0],
        })
        setIsShow(true)
        setCards(paymentMethods)
      } else {
        setIsShow(false)
        setSelectedCard('')
        setShowAddCard(true)
        updateData({
          isExistingCard: false,
          selectedCardToken: '',
        })
      }
    } catch (error) {
      const e = error as ErrorInitialData
      updateErrorData(e)
      setShowAddCard(true)
    }
  }

  const handleChange =
    (prop: keyof NewCardFormState) =>
      (event: React.ChangeEvent<HTMLInputElement>) => {
        if (prop === 'cardNumber') {
          cardValidation()
        }
        const newValues: any = { ...values, [prop]: event.target.value }
        if (
          newValues.name &&
          newValues.cardNumber &&
          newValues.expiryMonthYear &&
          newValues.cvv &&
          newValues.zipCode
        ) {
          updateData({ newCardDisableStatus: false })
        } else {
          updateData({ newCardDisableStatus: true })
        }
        setValues(newValues)
      }

  const setContextCardValue = () => {
    const cardNumber = values.cardNumber.replace(/[\s_+]/g, '')
    const expMonthYear = values.expiryMonthYear.split('/').map((d) => Number(d))
    const contextVal = {
      name: values.name,
      number: cardNumber,
      cvv: values.cvv,
      exp_month: expMonthYear[0],
      exp_year: expMonthYear[1],
      brand: cardNumber ? getBrandNameConverter(getBrandName(cardNumber)) : '',
      zip_code: values.zipCode,
    }
    updateData({ newCard: contextVal })
  }

  React.useEffect(() => {
    getPaymentInfo().catch((e) => console.error(e))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appMasterData.isLoggedIn])

  React.useEffect(() => {
    setContextCardValue()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values])

  const cardValidation = () => {
    const cardType = cardTypeValidation(values?.cardNumber)
    setAMEX(cardType === 'AMEX')
  }

  const isPaymentAccordionExpanded = React.useMemo(() => {
    return (
      appMasterData.isLoggedIn &&
      Boolean(appMasterData?.userInfo?.phone_number)
    )
  }, [
    appMasterData?.isAgree,
    appMasterData.isLoggedIn,
    appMasterData?.userInfo?.phone_number,
    isMembership,
  ])

  return (
    <>
      <div
        className={conditionalClasses(
          Boolean(isMembership),
          'membership-payment-section',
          '',
          'payment-section'
        )}
      >
        <Accordion expanded={isPaymentAccordionExpanded}>
          <AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
            <div className="payment-head">
              <Chip label="2" />
              Payment Method
            </div>
          </AccordionSummary>
          <AccordionDetails>
            <div className="payment-text body-2">
              {isMembership
                ? 'Select the card to use for your monthly billing.'
                : 'Your credit card is used to hold your appointment and will only be charged after your facial.'}
            </div>
            {!!cards && cards.length > 0 && isShow && (
              <Select
                className={
                  isMembership ? 'membership-card-selection' : 'card-selection'
                }
                value={selectedCard}
                onChange={onSelectCard}
                displayEmpty
                IconComponent={KeyboardArrowDownIcon}
                inputProps={{ 'aria-label': 'Without label' }}
                MenuProps={{
                  className: conditionalClasses(
                    Boolean(isMembership),
                    'membership-card-items',
                    'card-items'
                  ),
                }}
              >
                {cards.map((card) => {
                  return (
                    <MenuItem
                      value={card.token}
                      divider
                      key={card.id}
                      className="menu-item"
                    >
                      <div>
                        <img
                          src={getBrandImage(card?.brand ?? card.number)}
                          alt=""
                          loading="lazy"
                        />
                        <span className="body-1 card-num">
                          •••• {card.number.substring(card.number.length - 4)}
                        </span>
                        <span>•</span>
                        <CardExpireText card={card} />
                      </div>
                      <div className="trash-icon">
                        {selectedCard === card.token && (
                          <TrashIcon
                            onClick={() => {
                              handlePopupOpen(card.id)
                            }}
                          />
                        )}
                      </div>
                    </MenuItem>
                  )
                })}
                <MenuItem value="" divider>
                  <PlusCircleIcon />{' '}
                  <span className="addcard-text">Add New Card</span>
                </MenuItem>
              </Select>
            )}

            {showAddCard && (
              <div className="add-newcard-form">
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <TextField
                      label="Name on Card"
                      variant="filled"
                      fullWidth
                      value={values.name}
                      onChange={handleChange('name')}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <InputMask
                      mask={AMEX ? AMEX_CARD : NON_AMEX_CARD}
                      alwaysShowMask={false}
                      value={values.cardNumber}
                      onChange={handleChange('cardNumber')}
                    >
                      {/* @ts-ignore */}
                      {() => (
                        <TextField
                          label="Card Number"
                          variant="filled"
                          fullWidth
                        />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={6}>
                    <InputMask
                      mask="99 / 9999"
                      value={values.expiryMonthYear}
                      onChange={handleChange('expiryMonthYear')}
                    >
                      {/* @ts-ignore */}
                      {() => (
                        <TextField label="MM/YYYY" variant="filled" fullWidth />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={6}>
                    <InputMask
                      mask={AMEX ? '9999' : '999'}
                      onChange={handleChange('cvv')}
                      value={values.cvv}
                    >
                      {/* @ts-ignore */}
                      {() => (
                        <TextField label="CVV" variant="filled" fullWidth />
                      )}
                    </InputMask>
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Zip Code"
                      variant="filled"
                      fullWidth
                      value={values.zipCode}
                      onChange={handleChange('zipCode')}
                      inputProps={{ maxLength: 5 }}
                    />
                  </Grid>
                </Grid>
              </div>
            )}
            {!isMembership && (
              <>
                <hr className="custom-divider" />
                <AppointmentPromo />
              </>
            )}
          </AccordionDetails>
        </Accordion>
      </div>
      <CustomDialog isModalOpen={isOpen} handleClose={() => setIsOpen(false)}>
        <>
          <h4 className="dialog-title">Are you sure?</h4>
          <span className="dialog-content body-1">
            Once you delete this card it cannot be recovered.
          </span>
          <div className="btn-section">
            <Button
              onClick={() => {
                removeCard().catch((e) => console.error(e))
              }}
              className="red-btn"
            >
              Delete Card
            </Button>
            <Button
              onClick={() => setIsOpen(false)}
              autoFocus
              className="red-btn red-btn-outlined body-1"
            >
              Keep Card
            </Button>
          </div>
        </>
      </CustomDialog>
    </>
  )
}

export default React.memo(AppointmentPayment)
